import { Divider, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Toolbar, Tooltip, Typography } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { DashboardItem } from '../../../core/dtos/DashboardItem';

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: 240,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
          overflowX: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: theme.spacing(8)
        }),
      },
    }),
  );

type Props = {
  appName: string;
  drawerItems: DashboardItem[];
  open: boolean;
  drawerItemIndex: number;
  toggleDrawer: () => void;
  onDrawerItemClick: (index: number, path: string) => void
}

function DesktopDrawer (props: Props) {

  const { appName, drawerItems, open, drawerItemIndex, onDrawerItemClick, toggleDrawer } = props;

  return (
    <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            px: [0]
          }}
        >
          <Typography variant='h6' pl={2}>{appName}</Typography>
          <IconButton onClick={toggleDrawer} sx={{marginLeft: 'auto'}}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          {drawerItems.map(
            (item, index) => (
              <ListItemButton
                selected={index === drawerItemIndex}
                key={"dashboar_list_item_" + index}
                onClick={() => onDrawerItemClick(index, item.path)}
              >
                <Tooltip title={item.name} enterDelay={open ? 9999 : 500}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                </Tooltip>
                <ListItemText primary={item.name} />
              </ListItemButton>
            )
          )}
        </List>
      </Drawer>
  );
}

export default DesktopDrawer;