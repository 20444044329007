import { Provider } from 'react-redux';
import App, { AppProps } from "./App";
import { store } from "../../store";

function AppProvider (props: AppProps) {

    return(
        <Provider store={store}>
            <App {...props}/>
        </Provider>
    )
}

export default AppProvider;