import { Divider, IconButton, List, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer, Toolbar, Tooltip, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { DashboardItem } from '../../../core/dtos/DashboardItem';
import { useTheme } from '@emotion/react';

type Props = {
  appName: string;
  drawerItems: DashboardItem[];
  open: boolean;
  drawerItemIndex: number;
  toggleDrawer: () => void;
  onDrawerItemClick: (index: number, path: string) => void
}

const styles = {
  '& .MuiDrawer-paper': {
    width: 'calc(100vw / 3 * 2)'
  }
}

function MobileDrawer (props: Props) {

  const { appName, drawerItems, open, drawerItemIndex, onDrawerItemClick, toggleDrawer } = props;

  const theme = useTheme() as Theme;

  const handleDrawerItemClick = (index: number, path: string) => {
    toggleDrawer();
    onDrawerItemClick(index, path);
  }

  return (
    <SwipeableDrawer open={open} sx={styles} onOpen={toggleDrawer} onClose={toggleDrawer}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            px: [0],
            zIndex: theme.zIndex.drawer
          }}
        >
          <Typography variant='subtitle1' pl={2}>{appName}</Typography>
          <IconButton onClick={toggleDrawer} sx={{marginLeft: 'auto'}}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          {drawerItems.map(
            (item, index) => (
              <ListItemButton
                selected={index === drawerItemIndex}
                key={"dashboar_list_item_" + index}
                onClick={() => handleDrawerItemClick(index, item.path)}
              >
                <Tooltip title={item.name} enterDelay={open ? 9999 : 500}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                </Tooltip>
                <ListItemText primary={item.name} />
              </ListItemButton>
            )
          )}
        </List>
      </SwipeableDrawer>
  );
}

export default MobileDrawer;