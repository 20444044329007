import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import { authSlice } from './slices/authSlice'
import { snackbarSlice } from './slices/snackbarSlice';
import { i18nSlice } from './slices/i18nSlice';
import { api } from '../core/api/config';

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    snackbar: snackbarSlice.reducer,
    i18n: i18nSlice.reducer,
    [api.reducerPath]: api.reducer
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware)
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch)