import { BaseEntityForm } from "@cetma/skeleton-app/core/dtos/forms/BaseForm";
import { GridRenderCellParams } from "@mui/x-data-grid";
import * as yup from "yup";

const panelForm: BaseEntityForm = {
    columns: [
        {
            field: 'panelId',
            fieldType: 'id',
            hideColumn: true,
            headerName: 'Id'
        },
        {
            field: 'panelImg',
            fieldType: 'image',
            hideColumn: false,
            headerName: 'Image',
            sortable: false,
            width: 60,
            acceptFile: 'image/*',
            renderCell: (params: GridRenderCellParams) => <img style={{ padding: 4 }} height={44} src={params.value} alt="" />
        },
        {
            field: 'panelName',
            fieldType: 'string',
            headerName: 'Name',
            flex: 1
        },
        {
            field: 'panelGlbName',
            fieldType: 'string',
            hideColumn: true,
            hideForm: true,
            headerName: 'Glb name'
        },
        {
            field: 'panelPointsNumber',
            fieldType: 'number',
            hideColumn: true,
            headerName: 'Points number'
        },
        {
            field: 'panelCategoryId',
            fieldType: 'select',
            hideColumn: true,
            headerName: 'Category'
        },
        {
            field: 'panelGlb',
            fieldType: 'file',
            hideColumn: true,
            headerName: 'Glb',
            fileNameField: 'panelGlbName',
            acceptFile: '.glb'
        }
    ],
    validation: yup.object({
        panelName: yup.string().required("error.validation.required"),
        panelGlbName: yup.string().required("error.validation.required"),
        panelPointsNumber: yup.number().required("error.validation.required").typeError("error.validation.type.number"),
        panelCategoryId: yup.string().required("error.validation.required"),
        panelGlb: yup.string().required("error.validation.required"),
        panelImg: yup.string().required("error.validation.required")
    })
}

export default panelForm;