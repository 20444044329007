import { I18n } from "@cetma/skeleton-app/store/slices/i18nSlice";

const i18n: I18n = {
    en: {
        environment: "Environment",
        confirmDialog: {
            title: 'Confirm delete',
            text: 'Are you sure you wantto proceed with cancellation?',
            confirm: 'Confirm',
            cancel: 'Cancel'
        },
        button: {
            confirm: "Ok",
            cancel: "Cancel"
        },
        form: {
            edit: "Edit",
            new: "New",
            reset: "Reset",
            save: "Save"
        },
        table: {
            delete: "Delete",
            deleteRows: "Delete %length% rows",
            add: "Add",
            edit: "Edit",
            close: "Close",
            refresh: "Refresh",
            noRowsLabel: "No data to display"
        },
        error: {
            validation: {
                required: "Field mandatory",
                type:{
                    number: "Field must be a valid number"
                }
            }
        }
    },
    it: {
        environment: "Ambiente",
        confirmDialog: {
            title: 'Conferma eliminazione',
            text: 'Sei sicuro di voler procedere con la cancellazione?',
            confirm: 'Conferma',
            cancel: 'Annulla'
        },
        button: {
            confirm: "Ok",
            cancel: "Annulla"
        },
        form: {
            edit: "Modifica",
            new: "Nuovo",
            reset: "Reset",
            save: "Salva"
        },
        table: {
            delete: "Elimina",
            deleteRows: "Elimina %length% righe",
            add: "Aggiungi",
            edit: "Modifica",
            close: "Chiudi",
            refresh: "Aggiorna",
            noRowsLabel: "Nessun dato da visualizzare"
        },
        error: {
            validation: {
                required: "Campo obbligatorio",
                type:{
                    number: "Il campo deve essere un numero"
                }
            }
        }
    }
}

export default i18n;