import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { IconButton, Toolbar, Typography } from '@mui/material';
import { DashboardItem } from '../../../core/dtos/DashboardItem';

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.appBar,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    })
}));

type Props = {
    drawerItems: DashboardItem[];
    open: boolean;
    drawerItemIndex: number;
    toggleDrawer: () => void;
    onDrawerItemClick: (index: number, path: string) => void
}

function MobileAppBar(props: Props) {

    const { open, drawerItems, drawerItemIndex, toggleDrawer } = props;

    return (
        <AppBar position="absolute" open={open}>
            <Toolbar sx={{ pr: '24px' }}>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                    sx={{ marginRight: '16px' }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1 }}
                >
                    {drawerItems[drawerItemIndex]?.name}
                </Typography>
            </Toolbar>
        </AppBar>
    )
}

export default MobileAppBar;