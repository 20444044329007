import { BaseEntityForm } from "@cetma/skeleton-app/core/dtos/forms/BaseForm";
import * as yup from "yup";

const furnitureCategoryForm: BaseEntityForm = {
    columns: [    
        { field: 'furnitureCategoryId', fieldType: 'id', hideColumn: true, headerName: 'Id' },
        { field: 'furnitureCategoryName', fieldType: 'string', hideColumn: false, headerName: 'Name', flex: 1 },
    ],
    validation:  yup.object({
        furnitureCategoryName: yup.string().required("error.validation.required")
    })
}

export default furnitureCategoryForm;