import { BaseEntityForm } from "@cetma/skeleton-app/core/dtos/forms/BaseForm";
import { GridRenderCellParams } from "@mui/x-data-grid";
import * as yup from "yup";

const furnitureForm: BaseEntityForm = {
    columns: [
        {
            field: 'furnitureId',
            fieldType: 'id',
            hideColumn: true,
            headerName: 'Id'
        },
        {
            field: 'furnitureImg',
            fieldType: 'image',
            hideColumn: false,
            headerName: 'Image',
            acceptFile: 'image/*',
            sortable: false,
            width: 60,
            renderCell: (params: GridRenderCellParams) => <img style={{ padding: 4 }} height={44} src={params.value} alt="" />
        },
        {
            field: 'furnitureName',
            fieldType: 'string',
            headerName: 'Name',
            flex: 1
        },
        {
            field: 'furnitureGlbName',
            fieldType: 'string',
            hideColumn: true,
            hideForm: true,
            headerName: 'Glb name'
        },
        {
            field: 'furnitureCategoryId',
            fieldType: 'select',
            hideColumn: true,
            headerName: 'Category'
        },
        {
            field: 'furnitureGlb',
            fieldType: 'file',
            hideColumn: true,
            headerName: 'Glb',
            fileNameField: 'furnitureGlbName',
            acceptFile: '.glb'
        },
    ],
    validation: yup.object({
        furnitureName: yup.string().required("error.validation.required"),
        furnitureGlbName: yup.string().required("error.validation.required"),
        furnitureCategoryId: yup.string().required("error.validation.required"),
        furnitureGlb: yup.string().required("error.validation.required"),
        furnitureImg: yup.string().required("error.validation.required"),
    })
}

export default furnitureForm;