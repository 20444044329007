import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { LoginRequest } from '../core/dtos/requests/LoginRequest';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useLazyLoginQuery } from '../core/api/config';
import { setCredentials } from '../store/slices/authSlice';

export default function Login() {

  const dispatch = useDispatch<AppDispatch>();

  const [login, {isLoading, isError}] = useLazyLoginQuery();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const request: LoginRequest = {
      email: data.get('email')?.toString() ?? '',
      password: data.get('password')?.toString() ?? '',
    };
    
    try{
      const user = await login(request).unwrap();
      dispatch(setCredentials(user));
    } catch(e){
      
    }
  };

  const user = useSelector((state: RootState) => state.auth.user)
  const navigate = useNavigate();

  React.useEffect(
    () => {
      if(user){
        navigate("/dashboard");
      }
    }, [user, navigate ]
  )

  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            My Art Gallery Backoffice
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <LoadingButton
              loading={isLoading && !isError}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </LoadingButton>
          </Box>
        </Box>
      </Container>
  );
}