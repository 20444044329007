import { BaseEntityForm } from "@cetma/skeleton-app/core/dtos/forms/BaseForm";
import { GridRenderCellParams } from "@mui/x-data-grid";
import * as yup from "yup";

const environmentForm: BaseEntityForm = {
    columns: [
        {
            field: 'environmentId',
            fieldType: 'id',
            hideColumn: true,
            headerName: 'Id'
        },
        {
            field: 'environmentImg',
            fieldType: 'image',
            hideColumn: false,
            headerName: 'Image',
            sortable: false,
            width: 60,
            acceptFile: 'image/*',
            renderCell: (params: GridRenderCellParams) => <img style={{ padding: 4 }} height={44} src={params.value} alt="" />
        },
        {
            field: 'environmentName',
            fieldType: 'string',
            headerName: 'Name',
            flex: 1
        },
        {
            field: 'environmentGlbName',
            fieldType: 'string',
            hideColumn: true,
            hideForm: true,
            headerName: 'Glb name'
        },
        {
            field: 'environmentCategoryId',
            fieldType: 'select',
            hideColumn: true,
            headerName: 'Category'
        },
        {
            field: 'environmentGlb',
            fieldType: 'file',
            hideColumn: true,
            headerName: 'Glb',
            fileNameField: 'environmentGlbName',
            acceptFile: '.glb'
        }
    ],
    validation: yup.object({
        environmentName: yup.string().required("error.validation.required"),
        environmentGlbName: yup.string().required("error.validation.required"),
        environmentCategoryId: yup.string().required("error.validation.required"),
        environmentGlb: yup.string().required("error.validation.required"),
        environmentImg: yup.string().required("error.validation.required"),
    })
}

export default environmentForm;