import { BaseEntityForm } from "@cetma/skeleton-app/core/dtos/forms/BaseForm";
import * as yup from "yup";

const panelCategoryForm: BaseEntityForm = {
    columns: [    
        { field: 'panelCategoryId', fieldType: 'id', hideColumn: true, headerName: 'Id' },
        { field: 'panelCategoryName', fieldType: 'string', hideColumn: false, headerName: 'Name', flex: 1 },
    ],
    validation:  yup.object({
        panelCategoryName: yup.string().required("error.validation.required")
    })
}

export default panelCategoryForm;