
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Alert, Slide, SlideProps, Snackbar, ThemeProvider } from '@mui/material';
import Login from '../../pages/Login';
import Dashboard from '../../pages/dashboard';
import React, { useEffect, useState } from 'react';
import { AppDispatch, RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardItem } from '../../core/dtos/DashboardItem';
import { closeMessage } from '../../store/slices/snackbarSlice';
import { AppTheme } from '../../core/dtos/AppTheme';
import { I18n, initI18n } from '../../store/slices/i18nSlice';

type TransitionProps = Omit<SlideProps, 'direction'>;

export type AppWrapperProps = {
  children: JSX.Element|JSX.Element[]
}

export type AppProps = {
  name: string,
  dashboardItems: DashboardItem[],
  theme: AppTheme,
  i18n: I18n, 
  disableAuth?: boolean,
  Wrapper?: (props: AppWrapperProps) => JSX.Element
}

function App(props: AppProps) {

  const {
    name,
    i18n,
    dashboardItems,
    theme,
    Wrapper
  } = props;

  const dispatch = useDispatch<AppDispatch>();

  const message = useSelector((state: RootState) => state.snackbar.message)
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  useEffect(() => {
      setOpenSnackbar(message !== null)
  },[message])

  useEffect(() => {
      //const lang = window.navigator.language.substring(0, 2);
      dispatch(initI18n(i18n));
      //dispatch(setLanguage(lang));
  })

  const handleCloseSnackbar = (event: React.SyntheticEvent | Event, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
    setTimeout(() => { dispatch(closeMessage()) }, 300);
  }

  function TransitionUp(props: TransitionProps) {
    return <Slide {...props} direction="up" />;
  }

  const mainRoutes = (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route 
        path="dashboard/*" 
        element={
          <PrivateRoute disableAuth={props.disableAuth}><Dashboard appName={name} drawerItems={dashboardItems} /></PrivateRoute>
        } 
      />
      <Route 
        path="*" 
        element={
          <PrivateRoute disableAuth={props.disableAuth}><Dashboard appName={name} drawerItems={dashboardItems} /></PrivateRoute>
        }
      />
    </Routes>
  );

  return (
    <ThemeProvider theme={theme}>  
      <BrowserRouter>
        {Wrapper ? <Wrapper>{mainRoutes}</Wrapper> : mainRoutes}        
      </BrowserRouter>

      <Snackbar 
        open={openSnackbar}  
        autoHideDuration={3000} 
        TransitionComponent={TransitionUp}
        onClose={handleCloseSnackbar}
      >
        <Alert sx={{ width: { xs: '100%' } }} severity={message?.severity ?? 'info'} variant='filled'>{message?.text ?? ''}</Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

const PrivateRoute = (props: {children: React.ReactNode, disableAuth?: boolean}) => {
  
  const user = useSelector((state: RootState) => state.auth.user);
  let location = useLocation();
  
  if(!user && (props.disableAuth === false || props.disableAuth === undefined)) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }
  
  return <>{props.children}</>;
};

export default App;