import { PayloadAction, createSlice, isFulfilled, isRejectedWithValue } from '@reduxjs/toolkit'
import { SnackbarMessage } from '../../core/dtos/SnackbarMessage'

type SnackbarState = {
  message: SnackbarMessage | null
}

const initialState: SnackbarState = {
  message: null
}

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: initialState,
  reducers: {
    showMessage: (state: SnackbarState, action: PayloadAction<SnackbarMessage>) => { state.message = action.payload },
    closeMessage: (state: SnackbarState) => { state.message = null } 
  },
  extraReducers: (builder) => {
    builder.addMatcher(isFulfilled, (state, action) => {
      const isLogin = (action.meta.arg as any).endpointName === "login";
      if(!isLogin && action.type.indexOf("executeMutation") > 0){
        state.message = { text: 'Operation succesful', severity: 'success'}
      }
    })
    builder.addMatcher(isRejectedWithValue, (state, action) => {
      state.message = { text: resolveErrorMessage((action.payload as any).data?.error), severity: 'error'}
    })
  }
})

const resolveErrorMessage = (errorCode: string) => {
  switch (errorCode) {
    case 'OBJECT_NOT_FOUND':
      return 'No element found';
    case 'MANDATORY_FIELD_MISSING':
      return 'Fill all mandatory fields';
    default:
      return 'Error during operation';
  }
}

export const { showMessage, closeMessage } = snackbarSlice.actions

export default snackbarSlice.reducer