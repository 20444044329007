import { AppWrapperProps } from "@cetma/skeleton-app/components/App/App";
import { generateCrudApi } from "@cetma/skeleton-app/index";

function MyArtGalleryBackoffice(props: AppWrapperProps){

    generateCrudApi('furnitureCategory', '/mde/rest-api/v1/furnitureCategory');
    generateCrudApi('furniture', '/mde/rest-api/v1/furniture');
    generateCrudApi('workspace', '/mde/rest-api/v1/workspace');
    generateCrudApi('sculpture', '/mde/rest-api/v1/sculpture');
    generateCrudApi('environmentCategory', '/mde/rest-api/v1/environmentCategory');
    generateCrudApi('environment', '/mde/rest-api/v1/environment');
    generateCrudApi('panelCategory', '/mde/rest-api/v1/panelCategory');
    generateCrudApi('panel', '/mde/rest-api/v1/panel');

    return (
        <>
            {props.children}
        </>
    );
}

export default MyArtGalleryBackoffice;