import { AppBar, Box, Button, FormControl, IconButton, MenuItem, Stack, TextField, Toolbar, Typography } from "@mui/material";
import { BaseEntityForm, EntityField } from "../../../core/dtos/forms/BaseForm"
import { BaseEntity } from "../../../core/entities/BaseEntity";
import { useEffect } from "react";
import UploadButton from "./UploadButton";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { isMobile } from "react-device-detect";
import { ArrowBack } from "@mui/icons-material";
import { translate } from "@cetma/skeleton-app/util";

export type FormLabel = {
    entity: string,
    save: string,
    reset: string,
    edit: string,
    new: string
}

type Props<T extends BaseEntity> = {
    label: Partial<FormLabel>,
    form: BaseEntityForm,
    data: T,
    selectValues?: {[key: string]: T[]},
    isLoading?: boolean,
    isFetching?: boolean;
    onSave: (data: T) => void,
    onReset?: () => void,
    onClose?: () => void,
}

export default function CrudForm<T extends BaseEntity>(props: Props<T>) {

    const { form, data, label, isLoading, isFetching, selectValues, onSave, onReset, onClose } = props;

    const { 
        control, 
        register, 
        handleSubmit, 
        setValue, 
        getValues,
        reset,
        trigger,
        formState: { errors },
    } = useForm({
        defaultValues: data as any,
        resolver: form.validation && yupResolver(form.validation)
    });

    useEffect(() => reset(data), [data, reset]);

    const handleFileUpload = (evt: React.ChangeEvent<HTMLInputElement>, fileNameField: string | undefined) => {
        const file = evt.target.files?.item(0);
        if(file){
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const base64 = reader.result;
                setValue(evt.target.name, base64);
                trigger(evt.target.name);
                if(fileNameField){
                    setValue(fileNameField, file.name);
                    trigger(fileNameField);
                }
            };
        }
    }

    const handleFileDelete = (col: EntityField, fileNameCol: EntityField | undefined) => {
        setValue(col.field, null);
        trigger(col.field);
        if(fileNameCol){
            setValue(fileNameCol.field, null);
            trigger(fileNameCol.field);
        }
    }

    const renderFields = () => {
        const fields = form.columns.map((col) => {
            
            if((col.fieldType === 'id' && !getValues(col.field)) || col.hideForm){
                return null;
            }

            let errorMsg = errors[col.field]?.message?.toString();
            errorMsg = errorMsg ? translate(errorMsg) : errorMsg;
            const hasError = errorMsg !== undefined;

            if(col.fieldType === 'image' || col.fieldType === 'file'){
                let fileCol = form.columns.find((c) => c.field === col.fileNameField);
                let fileError = col.fileNameField && errors[col.fileNameField]?.message?.toString();
                let fileErrorMsg = fileError && translate(fileError)
                if(!fileCol){
                    //fileCol = form.columns.find((c) => c.field === col.field);
                    fileError = col.field && errors[col.field]?.message?.toString();
                    fileErrorMsg = fileError && translate(fileError);
                }
                const base64 = getValues(col.field);
                return (
                    <FormControl fullWidth margin="normal" key={"edit_form_" + col.field}>
                        <UploadButton
                            label={col.headerName}
                            fileType={col.fieldType}
                            errorMsg={fileErrorMsg}
                            base64={base64}
                            onDelete={() => handleFileDelete(col, fileCol)}
                            control={control}
                            register={register}
                            getValues={getValues}
                            textField={fileCol && {
                                name:fileCol.field,
                                label: fileCol.headerName
                            }}
                            button={{
                                accept: col.acceptFile ?? "*/*",
                                name: col.field,
                                label: "Upload",
                                onChange: (evt) => handleFileUpload(evt, fileCol?.field)
                            }}
                        />
                    </FormControl>
                );
            } else {
                return (
                    <FormControl fullWidth margin="normal" key={"edit_form_" + col.field}>
                        <Controller
                            name={col.field}
                            control={control}
                            render={() => {
                                switch(col.fieldType){
                                    case 'select':
                                        const items = (selectValues && selectValues[col.field]) ?? [];
                                        return (
                                            <TextField
                                                disabled={isLoading || isFetching}
                                                variant="standard"
                                                size="small"
                                                select
                                                label={col.headerName}
                                                error={hasError}
                                                helperText={errorMsg}
                                                value={getValues(col.field) ?? ''}
                                                {...register(col.field)} 
                                            >
                                                {(items).map((e: any) => <MenuItem key={col.field + "_" + e.id} value={e.id}>{e.name}</MenuItem>)}   
                                            </TextField>
                                        );
                                    default:
                                        return (
                                            <TextField 
                                                variant="standard"
                                                size="small"
                                                disabled={col.fieldType === "id" || isLoading || isFetching}
                                                label={col.headerName} 
                                                type={col.fieldType}
                                                error={hasError}
                                                helperText={errorMsg}
                                                value={getValues(col.field) ?? ''}
                                                {...register(col.field)} 
                                            />
                                        )
                                }
                            }}
                        />
                    </FormControl>
                );
            }
        })

        return <>{fields}</>;
    }

    const onSubmit = (data: any) => {
        onSave(data)
    }

    const handleReset = () => {
        reset(data);
        if(onReset){
            onReset();
        }
    }

    const fieldId = form.columns.find((c) => c.fieldType === 'id')?.field;
    const title = (fieldId && getValues(fieldId) ? (label?.edit ?? 'Edit') : (label?.new ?? 'New') ) + ' ' + (label?.entity ?? '');

    return(
        <>
            {!isMobile ? 
                <Typography variant="h5">{title}</Typography>
                :
                <AppBar>
                    <Toolbar sx={{ pr: '24px' }} color="">
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={onClose}
                            sx={{ marginRight: '16px' }}
                        >
                            <ArrowBack />
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            {title}
                        </Typography>
                    </Toolbar>
                </AppBar>
            }
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                {isMobile && <Toolbar />}
                {renderFields()}
                <Stack
                    spacing={2}
                    direction="row"
                    marginTop={4}
                    justifyContent={"end"}
                    position={isMobile ? 'absolute' : undefined}
                    bottom={16}
                    right={16}
                >
                    <Button 
                        variant="text" 
                        onClick={handleReset}
                        disabled={isLoading || isFetching}
                    >
                        {label?.reset}
                    </Button>
                    <LoadingButton 
                        variant="contained" 
                        disabled={isFetching}
                        loading={isLoading} 
                        type="submit"
                    >
                            {label?.save}
                    </LoadingButton>
                </Stack>
            </Box>
        </>
    )
}