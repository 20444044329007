import DeleteIcon from "@mui/icons-material/Delete";
import UploadIcon from "@mui/icons-material/Upload";
import { IconButton, Stack, TextField } from "@mui/material";
import { useRef } from "react";
import { Control, Controller, RegisterOptions, UseFormGetValues, UseFormRegisterReturn } from "react-hook-form";

type Props = {
    label?: string,
    base64?: string,
    fileType: string,
    onDelete: () => void,
    errorMsg?: string,
    control: Control<any, any>,
    register: (name: string, options?: RegisterOptions<any, string> | undefined) => UseFormRegisterReturn<string>,
    getValues: UseFormGetValues<any>
    button: {
        accept: string,
        name: string, 
        label: string, 
        onChange: (evt: React.ChangeEvent<HTMLInputElement>, file: File, base64: string | ArrayBuffer | null) => void,
    },
    textField?: {
        name: string, 
        label?: string
    }
}

export default function UploadButton(props: Props) {

    const { button,
         textField,
         fileType,
         base64,
         label,
         errorMsg,
         control,
         getValues,
         register,
         onDelete
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);

    const handleFileUpload = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const file = evt.target.files?.item(0);
        if(file){
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const base64 = reader.result;
                button.onChange(evt, file, base64);
            };
        }
    }

    const handleDelete = () => {
        if(inputRef.current){
            inputRef.current.value = '';
        }
        onDelete();
    }

    return (
        <Stack direction="row" alignItems="center" spacing={2}>
            <div>
                {!textField && !base64 && (
                    <TextField 
                        variant="standard"
                        size="small"
                        disabled
                        label={label} 
                        error={errorMsg !== undefined}
                        helperText={errorMsg}
                    />
                )}
                {textField && (
                    <Controller
                        name={textField.name}
                        control={control}
                        render={
                            () => (
                                <TextField 
                                    variant="standard"
                                    size="small"
                                    disabled={!base64}
                                    label={textField.label} 
                                    error={errorMsg !== undefined}
                                    helperText={errorMsg}
                                    value={getValues(textField.name) ?? ''}
                                    {...register(textField.name)}
                                />
                            )
                        }
                    /> 
                )}
                {base64 && fileType === 'image' && <img alt="not available" src={base64} style={{maxWidth: 80}}/>}
            </div>
            <div>
                {base64 && (
                    <IconButton size="small" onClick={handleDelete}><DeleteIcon /></IconButton>
                )}
                <IconButton component="label" size="small">
                    <UploadIcon />
                    <input ref={inputRef} name={button.name} hidden accept={button.accept} type="file" onChange={handleFileUpload}/>
                </IconButton>
            </div>
        </Stack>
    )
}