import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type i18nItem = string | { [key: string]: string | i18nItem }
export type I18n = {
  [lang: string]: {
    [key: string]: i18nItem 
  }
}

type language = 'en' | 'es' | 'it';

type I18nState = {
  lang: language,
  translations: I18n
}

const initialState: I18nState = {
  lang: 'en',
  translations: {}
}

export const i18nSlice = createSlice({
  name: 'i18n',
  initialState: initialState,
  reducers: {
    initI18n: (state: I18nState, action: PayloadAction<I18n>) => {
      state.translations = action.payload;
    },
    setLanguage: (state: I18nState, action: PayloadAction<language>) => {
      state.lang = action.payload;
    }
  }
})

export const { initI18n, setLanguage } = i18nSlice.actions

export default i18nSlice.reducer