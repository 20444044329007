import { DataGridProps, GridPaginationModel, GridSortModel } from "@mui/x-data-grid";
import { FormLabel } from "./Form";
import CrudTable, { TableLabel } from "./Table";
import { BaseEntity } from "../../core/entities/BaseEntity";
import { toPascalCase } from "../../util";
import { BaseEntityForm } from "../../core/dtos/forms/BaseForm";
import { ConfirmDialogLabel } from "../ConfirmDialog";

type Props<T extends BaseEntity> = {
    entity: { name: string, label: string };
    api: { [key: string]: any };
    form: BaseEntityForm;
    newEntityData?: T;
    datagridProps: Partial<DataGridProps>;
    formLabel?: Partial<FormLabel>;
    tableLabel?: Partial<TableLabel>;
    confirmDialogLabel?: Partial<ConfirmDialogLabel>;
    searchParams?: string;
    selectValues?: {[key: string]: T[]};
    tableHeight?: string;
    onRowClick?: (entity: T) => void;
    onPaginationSort?: (pagination: GridPaginationModel, sort: GridSortModel) => void;
}

export default function Crud<T extends BaseEntity>(props: Props<T>) {

    const { 
        api, 
        entity, 
        newEntityData,
        form, 
        datagridProps,
        formLabel,
        tableLabel,
        confirmDialogLabel,
        selectValues,
        searchParams,
        tableHeight,
        onRowClick,
        onPaginationSort
    } = props;
    const entityName = toPascalCase(entity.name);

    // api hooks
    const useFindEntity = searchParams ? api[`useFind${entityName}Query`] : api[`useFindAll${entityName}Query`];
    const useFindEntityById = api[`useLazyFind${entityName}ByIdQuery`];
    const useAddEntity = api[`useAdd${entityName}Mutation`];
    const useUpdateEntity = api[`useUpdate${entityName}Mutation`];
    const useDeleteEntity = api[`useDelete${entityName}Mutation`];
    const useDeleteEntityById = api[`useDelete${entityName}ByIdMutation`];

    const { data, isLoading: isDataGridLoading, isFetching: isDataGridFetching, refetch } = useFindEntity(searchParams ? searchParams.toString() : undefined);
    const [ findEntityById, {data: editEntityData, isFetching: isFindByIdFetching, error: isFindByIdError}] = useFindEntityById();
    const [newEntity, {isLoading: isAddLoading, error: isAddError}] = useAddEntity();
    const [updateEntity, {isLoading: isUpdateLoading, error: isUpdateError}] = useUpdateEntity();
    const [deleteEntity, {isLoading: isDeleteLoading, error: isDeleteError}] = useDeleteEntity();
    const [deleteEntityById, {isLoading: isDeleteByIdLoading, error: isDeleteByIdError}] = useDeleteEntityById();

    const handleDelete = (entities: T[]) => {
        if(entities.length === 1){
            deleteEntityById(entities[0].id);
        } else {
            deleteEntity(entities.map((e) => e.id!));
        }
    }

    const handleSave = (entity: T) => {
        const fieldId = form.columns.find((c) => c.fieldType === 'id')?.field!;
        if (entity[fieldId]) {
            entity.id = entity[fieldId];
            updateEntity(entity);
        } else {
            newEntity(entity);
        }
    }

    const handleEdit = (entity: T) => {
        findEntityById(entity.id);
    }

    return (
        <CrudTable<T>
            tableHeight={tableHeight}
            onSave={handleSave}
            onDelete={handleDelete}
            onRowClick={onRowClick}
            onEdit={handleEdit}
            onPaginationSortChange={onPaginationSort}
            onRefresh={refetch}
            data={data}
            isDataGridLoading={(isDataGridLoading || isDataGridFetching || isDeleteLoading || isDeleteByIdLoading) && !(isAddError || isUpdateError || isDeleteError || isDeleteByIdError)}
            isFormLoading={(isAddLoading || isUpdateLoading) && !(isAddError || isUpdateError || isDeleteError || isDeleteByIdError || isFindByIdError)}
            isFormFetching={isFindByIdFetching}
            isDeleteLoading={isDeleteLoading || isDeleteByIdLoading}
            entity={entity}
            form={form}
            newEntityData={newEntityData}
            editEntityData={editEntityData}
            datagridProps={datagridProps}
            formLabel={formLabel}
            confirmDialogLabel={confirmDialogLabel}
            tableLabel={tableLabel}
            selectValues={selectValues}
        />
    )
}