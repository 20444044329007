import { DataGrid, DataGridProps, GridColDef, GridFooterContainer, GridLocaleText, GridPagination, GridPaginationModel, GridRenderCellParams, GridRowId, GridRowParams, GridSortModel } from "@mui/x-data-grid";
import { BaseEntityForm, EntityField } from "../../../core/dtos/forms/BaseForm";
import { Container, Dialog, DialogContent, Grid, IconButton, LinearProgress, Paper, Stack, Tooltip, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import CrudForm, { FormLabel } from "../Form";
import { BaseEntity } from "../../../core/entities/BaseEntity";
import {isMobile} from 'react-device-detect';
import { Page } from "../../../core/api";
import Fab from "../../Fab";
import { LoadingButton } from "@mui/lab";
// Icons
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";
import ConfirmDialog, { ConfirmDialogLabel } from "../../ConfirmDialog";

export type TableLabel = GridLocaleText & {
    delete: string;
    deleteRows: string;
    add: string;
    edit: string;
    close: string;
    refresh: string;
}

type Props<T extends BaseEntity> = {
    entity: {name: string, label: string};
    newEntityData?: T;
    editEntityData?: T;
    confirmDialogLabel?: Partial<ConfirmDialogLabel>;
    tableLabel?: Partial<TableLabel>;
    formLabel?: Partial<FormLabel>;
    form: BaseEntityForm;
    datagridProps?: Partial<DataGridProps>;
    data?: Page<T>;
    isDataGridLoading?: boolean; 
    isFormLoading?: boolean; 
    isFormFetching?: boolean;
    isDeleteLoading?: boolean;
    selectValues?: {[key: string]: T[]};
    tableHeight?: string;
    onPaginationSortChange?: (pagination: GridPaginationModel, sort: GridSortModel) => void;
    onRefresh?: () => void;
    onRowClick?: (entity: T) => void;
    onSave?: (entity: T) => void;
    onEdit?: (entity: T) => void;
    onDelete?: (entities: T[]) => void;
}

export default function CrudTable<T extends BaseEntity>(props: Props<T>) {

    const { 
        entity, 
        newEntityData,
        editEntityData,
        form, 
        tableLabel,
        formLabel, 
        confirmDialogLabel,
        datagridProps,
        data,
        isDataGridLoading,
        isFormLoading,
        isFormFetching,
        isDeleteLoading,
        selectValues,
        tableHeight,
        onRefresh,
        onPaginationSortChange,
        onRowClick,
        onEdit,
        onSave,
        onDelete
    } = props;

    const theme = useTheme();

    const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
    const [rowToDelete, setRowToDelete] = useState<T>();
    const [selected, setSelected] = useState<T[]>();
    const [editEntity, setEditEntity] = useState<T>();
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 0, pageSize: 10 });
    const [sortModel, setSortModel] = useState<GridSortModel>();

    useEffect(() => { setEditEntity(editEntityData) }, [editEntityData, setEditEntity])
    
    // clear form if edit entity has been deleted
    useEffect(() => {
        const fieldId = form.columns.find((c) => c.fieldType === 'id')?.field!;
        if(data && editEntity && editEntity[fieldId]){
            const found = data.rows.findIndex((row) => row.id === editEntity[fieldId]) !== -1;
            if(!found){
                setEditEntity(undefined);
            }
        }
    }, [data, form, editEntity, setEditEntity]);

    const hasSelected = selected && selected.length > 0;

    const dgProps = {
        ...datagridProps,
        slots: {
            ...datagridProps?.slots,
            loadingOverlay: LinearProgress,
            footer: () => (
                <GridFooterContainer>
                    <Stack direction="row" spacing={2}>
                        {!isMobile && onRefresh && (
                            <LoadingButton 
                                variant="outlined" 
                                onClick={onRefresh}
                                loading={isDataGridLoading}
                                endIcon={<RefreshIcon />}
                            >
                                {tableLabel?.refresh ?? 'Refresh'}
                            </LoadingButton>
                        )}
                        {!isMobile && hasSelected && (
                            <LoadingButton
                                loading={isDeleteLoading}
                                variant="outlined" 
                                color="error"
                                onClick={() => setOpenConfirmDelete(true)}
                                endIcon={<DeleteIcon />}
                            >
                                {tableLabel?.deleteRows?.replace('%length%', selected.length.toString()) ?? `Delete ${selected.length} rows`}
                            </LoadingButton>
                        )}
                    </Stack>
                    {(!hasSelected || isMobile) && <>&nbsp;</>}
                    {data &&  data?.total > 10 && <GridPagination  />}
                </GridFooterContainer>
            )
        }
    }

    const columns: (GridColDef|EntityField)[] = [
        ...form.columns,
        {
            sortable: false,
            width: 120,
            field: ' ',
            renderCell: (params: GridRenderCellParams) => {
                return(
                    <Stack direction="row" spacing={2} justifyContent="end" width={"100%"}>
                        <Tooltip enterDelay={1000} enterNextDelay={1000} title={tableLabel?.edit ?? 'Edit'}>
                            <IconButton disabled={isFormFetching || isFormLoading} onClick={(evt) => handleEdit(evt, params.row)}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip enterDelay={1000} enterNextDelay={1000} title={tableLabel?.delete ?? 'Delete'}>
                            <IconButton disabled={isFormFetching || isFormLoading} onClick={(e) => handleRowDelete(e, params.row)}><DeleteIcon /></IconButton>
                        </Tooltip>
                    </Stack>
                )
            }
        }
    ]

    const toggleForm = () => {
        setEditEntity(editEntity ? undefined : newEntityData ?? {} as T);
    }

    const handleEdit = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>, row: GridRowParams)=> {
        evt.stopPropagation();
        if(onEdit){
            onEdit(row as unknown as T);
        }
    }

    const handleDelete = ()=> {
        setOpenConfirmDelete(false);
        if(onDelete && (rowToDelete)){
            onDelete([rowToDelete]);
        } else if (onDelete && selected){
            onDelete(selected);
        }
    }

    const handleRowDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, entity: T) => {
        e.stopPropagation();
        setRowToDelete(entity);
        setOpenConfirmDelete(true);
    }

    const closeConfirmDelete = () => {
        setRowToDelete(undefined);
        setOpenConfirmDelete(false);
    }

    const handleRowClick = (row: GridRowParams) => {
        if(onRowClick){
            onRowClick(row as unknown as T);
        }
    }

    const handleSave = (data: T) => {
        if(onSave){
            onSave(data);
        }
    }

    const handlePaginationSortChange = (pagination?: GridPaginationModel, sort?: GridSortModel) => {
        setPaginationModel(pagination ?? paginationModel);
        setSortModel(sort ?? sortModel);
        if(onPaginationSortChange) {
            onPaginationSortChange(pagination ?? paginationModel, sort ?? sortModel ?? []);
        }
    }

    const handleRowSelection = (rowIds: GridRowId[]) => {
        const selectedRows = data?.rows.filter((r) => {return r.id && rowIds.indexOf(r.id) !== -1} );
        setSelected(selectedRows);
    }

    const columnVisibilityModel: {[key: string]: boolean } = { };
    form.columns.forEach((col) => { columnVisibilityModel[col.field] = !col.hideColumn ?? true});

    return (
        <>
            <Container maxWidth={"xl"} sx={{padding: isMobile ? 0 : undefined}}>
                {onRefresh && isMobile && !isDataGridLoading && (
                    <IconButton 
                        onClick={onRefresh} 
                        size="medium"
                        sx={{
                            position: 'absolute', 
                            color: 'white',
                            top: 8, 
                            right: 8, 
                            zIndex: theme.zIndex.drawer + 1
                        }}
                    >
                        <RefreshIcon />
                    </IconButton>
                )}
                <Grid container spacing={editEntity && !isMobile ? 2 : 0}>
                    {/* Data table */}
                    <Grid item xs={editEntity && !isMobile ? 6 : 12}>
                        <DataGrid
                            style={{height: tableHeight}}
                            loading={isDataGridLoading || isFormLoading}
                            sx={{ border: 'none' }}
                            rowCount={data?.total ?? 0}
                            rows={data?.rows ?? []}
                            columns={columns}
                            columnVisibilityModel={columnVisibilityModel}
                            paginationModel={paginationModel}
                            paginationMode={onPaginationSortChange ? "server" : "client"}
                            pageSizeOptions={[10,20,50]}
                            onPaginationModelChange={(pagination: GridPaginationModel) => handlePaginationSortChange(pagination, undefined)}
                            onSortModelChange={(sort: GridSortModel) => handlePaginationSortChange(undefined, sort)}
                            sortModel={sortModel}
                            sortingMode={onPaginationSortChange ? "server" : "client"}
                            onRowClick={(e) => handleRowClick(e.row)}
                            onRowSelectionModelChange={handleRowSelection}
                            localeText={tableLabel}
                            {...dgProps}
                        />
                    </Grid>

                    {/* Edit form */}
                    {editEntity && !isMobile && (
                        <Grid item xs={editEntity ? 6 : 0}>
                            <Paper sx={{padding: 2}}>
                                <CrudForm<T> 
                                    label={{
                                        entity: entity.label,
                                        ...formLabel
                                    }} 
                                    form={form} 
                                    data={editEntity} 
                                    isLoading={isFormLoading}
                                    isFetching={isFormFetching}
                                    selectValues={selectValues}
                                    onSave={handleSave} 
                                    onReset={() => setSelected(undefined)}
                                />
                            </Paper>
                        </Grid>
                    )}
                    {isMobile && (
                        <Dialog fullScreen open={editEntity !== undefined}>
                            <DialogContent>
                                <CrudForm<T> 
                                    label={{
                                        entity: entity.label,
                                        ...formLabel
                                    }} 
                                    form={form} 
                                    data={editEntity ?? {} as T} 
                                    isLoading={isFormLoading}
                                    isFetching={isFormFetching}
                                    selectValues={selectValues}
                                    onSave={handleSave} 
                                    onReset={() => setSelected(undefined)}
                                    onClose={toggleForm}
                                />
                            </DialogContent>
                        </Dialog>
                    )}
                </Grid>

                <Fab
                    label={hasSelected && isMobile ? (tableLabel?.delete ?? 'delete') : editEntity ? (tableLabel?.close ?? 'close') : (tableLabel?.add ?? 'add')}
                    onClick={(e) => hasSelected && isMobile ? setOpenConfirmDelete(true) : toggleForm()}
                    placement="bottom-end"
                    color={hasSelected && isMobile ? "error" : "primary"}
                    icon={hasSelected && isMobile ? <DeleteIcon /> : editEntity && !isMobile ? <CloseIcon /> : <AddIcon />}
                    loading={hasSelected && isMobile && isDeleteLoading}
                />
            </Container>
            <ConfirmDialog 
                open={openConfirmDelete}
                label={confirmDialogLabel}
                onConfirm={() => handleDelete()}
                onCancel={() => closeConfirmDelete()}
            />
        </>
    )
}