import { BaseEntity } from "@cetma/skeleton-app/core/entities/BaseEntity";
import { DataGridProps } from "@mui/x-data-grid";
import { useState } from "react";
import { Box, Button } from "@mui/material";
import BackIcon from "@mui/icons-material/KeyboardArrowLeft";
import { BaseEntityForm } from "@cetma/skeleton-app/core/dtos/forms/BaseForm";
import { FormLabel } from "@cetma/skeleton-app/components/Crud/Form";
import { Crud } from "@cetma/skeleton-app/index";
import { TableLabel } from "@cetma/skeleton-app/components/Crud/Table";
import { ConfirmDialogLabel } from "@cetma/skeleton-app/components/ConfirmDialog";
import { toPascalCase } from "@cetma/skeleton-app/util";

type Props = {
    datagridProps: Partial<DataGridProps>,
    confirmDialogLabel?: Partial<ConfirmDialogLabel>,
    formLabel?: Partial<FormLabel>,
    tableLabel?: Partial<TableLabel>,
    api: { [key: string]: any },
    listForm: BaseEntityForm,
    listEntity: { name: string, label: string },
    detailForm: BaseEntityForm,
    detailEntity: { name: string, label: string, select: string[] },
    joinField: string
}

export default function ListDetail<L extends BaseEntity, D extends BaseEntity>(props: Props) {

    const {
        datagridProps, 
        formLabel, 
        tableLabel,
        confirmDialogLabel,
        api, 
        listForm,
        listEntity,
        detailForm,
        detailEntity,
        joinField
    } = props;
    
    const entityName = toPascalCase(listEntity.name);
    const useFindEntity = api[`useFindAll${entityName}Query`];
    
    // Preload list of categories
    const {data} = useFindEntity();
    
    const [category, setCategory] = useState<L|null>(null);
    const [detailSearchParams, setDetailSearchParams] = useState<string|undefined>();

    const handleCategoryRowClick = (category: L) => {
        setDetailSearchParams(category.id + '?select=' + detailEntity.select.join(','));
        setCategory(category);
    }

    const handleBackClick = () => {
        setCategory(null);
    }

    return (
        <Box padding={2}>
            {category === null && (
                <Crud<L>    
                    tableHeight={'calc(100vh - 200px)'} 
                    entity={listEntity} 
                    form={listForm} 
                    api={api}
                    datagridProps={datagridProps}
                    tableLabel={tableLabel}
                    formLabel={formLabel}
                    confirmDialogLabel={confirmDialogLabel}
                    onRowClick={handleCategoryRowClick}
                />
            )}

            {category && (
                <>
                    <Button startIcon={<BackIcon />} onClick={handleBackClick}>{category?.name}</Button>
                    <Crud<D>
                        tableHeight={'calc(100vh - 200px)'}  
                        entity={detailEntity} 
                        form={detailForm} 
                        api={api}
                        datagridProps={datagridProps}
                        tableLabel={tableLabel}
                        formLabel={formLabel}
                        confirmDialogLabel={confirmDialogLabel}
                        searchParams={detailSearchParams}
                        newEntityData={{[joinField]: category.id} as any}
                        selectValues={{[joinField]: data?.rows}}
                    />
                </>
            )}
        </Box>
    )
}