import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    accessToken: null,
    loading: false,
    error: null
  },
  reducers: {
    setCredentials: (state, action) => {
      state.user = action.payload.response.user;
      state.accessToken = action.payload.response.token;
    },
    logout: (state) => {
      state.user = null
    }
  }
})

export const { setCredentials, logout } = authSlice.actions

export default authSlice.reducer