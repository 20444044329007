import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import dashboardItems from './dashboardItems';
import theme from './theme';
import MyArtGalleryBackoffice from './MyArtGalleryBackoffice';
import { Provider } from '@cetma/skeleton-app/index';
import i18n from './i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider
      name='Backoffice'
      i18n={i18n}
      dashboardItems={dashboardItems}
      theme={theme}
      Wrapper={MyArtGalleryBackoffice}
    />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
