import { store } from "./store";
import _ from "lodash";

export function toPascalCase(string: string): string {
  return `${string}`
    .replace(new RegExp(/[-_]+/, 'g'), ' ')
    .replace(new RegExp(/[^\w\s]/, 'g'), '')
    .replace(
      new RegExp(/\s+(.)(\w*)/, 'g'),
      ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`,
    )
    .replace(new RegExp(/\w/), (s) => s.toUpperCase());
}

export function translate(key: string): string {
  const i18nState = store.getState().i18n;
  const lang = i18nState.lang;
  const translation = _.get(i18nState.translations[lang], key) as string|undefined;
  return translation ?? key;
}