import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { DashboardItem } from '../../core/dtos/DashboardItem';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import DesktopDrawer from './drawer/DesktopDrawer';
import MobileDrawer from './drawer/MobileDrawer';
import MobileAppBar from './appbar/MobileAppBar';
import DesktopAppBar from './appbar/DesktopAppBar';

type Props = {
  appName: string,
  drawerItems: DashboardItem[]
}

export default function Dashboard(props: Props) {

  const { appName, drawerItems } = props;
  const [open, setOpen] = useState(!isMobile);
  const [drawerItemIndex, setDrawerItemIndex] = useState(-1);

  const navigate = useNavigate();
  const DASHBOARD_PATH = "/dashboard";

  // navigate to first drawer item by default
  useEffect(() => {
    if(drawerItemIndex === -1){
      navigate(DASHBOARD_PATH + drawerItems[0].path);
      setDrawerItemIndex(0);
    }
  }, [drawerItemIndex, navigate, drawerItems]);

  const location = useLocation();
  useEffect(() => {
    const drawerItemIndex = drawerItems.findIndex((d) => location.pathname.indexOf(d.path) > 0);
    setDrawerItemIndex(drawerItemIndex);
  },[drawerItems, location.pathname, setDrawerItemIndex])

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleDrawerItemClick = (index: number, path: string) => {
    setDrawerItemIndex(index);
    navigate(DASHBOARD_PATH + path);
  }


  const AppBar = isMobile ? MobileAppBar : DesktopAppBar;
  const Drawer = isMobile ? MobileDrawer : DesktopDrawer;

  return (
    <Box sx={{ display: 'flex' }} position='relative'>
      <AppBar 
        drawerItems={drawerItems}
        open={open}
        drawerItemIndex={drawerItemIndex}
        toggleDrawer={toggleDrawer}
        onDrawerItemClick={handleDrawerItemClick} 
      />
      <Drawer 
        appName={appName}
        drawerItems={drawerItems}
        open={open}
        drawerItemIndex={drawerItemIndex}
        toggleDrawer={toggleDrawer}
        onDrawerItemClick={handleDrawerItemClick}
      />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Routes>
          {drawerItems.map((d, i) => (
            <Route 
              key={"dashboard_route_" + i}
              path={d.path}
              element={d.element}
            />
          ))}
        </Routes>
      </Box>
    </Box>
  );
}