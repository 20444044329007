
import FurnitureIcon from '@mui/icons-material/Forest';
import EnvironmentIcon from '@mui/icons-material/CropSquare';
import PanelIcon from '@mui/icons-material/Dashboard';
import { DashboardItem } from '@cetma/skeleton-app/core/dtos/DashboardItem';
import FurnitureCategory from './pages/furnitureCategory';
import EnvironmentCategory from './pages/environmentCategory';
import PanelCategory from './pages/panelCategory';
let dashboardItems: DashboardItem[] = [
    { icon: <FurnitureIcon />, name: "Furnitures", path: "/furniture-category", element: <FurnitureCategory /> },
    { icon: <EnvironmentIcon />, name: "Environments", path: "/environment-category", element: <EnvironmentCategory /> },
    { icon: <PanelIcon />, name: "Panels", path: "/panel-category", element: <PanelCategory /> }
] 
export default dashboardItems;