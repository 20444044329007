import environmentForm from "../../dtos/forms/environment";
import { datagridProps } from "../common";
import environmentCategoryForm from "../../dtos/forms/environment/Category";
import { api } from "@cetma/skeleton-app/index";
import ListDetail from "../../components/ListDetail";
import { ConfirmDialogLabel } from "@cetma/skeleton-app/components/ConfirmDialog";
import { FormLabel } from "@cetma/skeleton-app/components/Crud/Form";
import { translate } from "@cetma/skeleton-app/util";
import { TableLabel } from "@cetma/skeleton-app/components/Crud/Table";

export default function EnvironmentCategory() {

    const confirmDialogLabel: ConfirmDialogLabel = {
        title: translate('confirmDialog.title'),
        text: translate('confirmDialog.text'),
        confirm: translate('confirmDialog.confirm'),
        cancel: translate('confirmDialog.cancel')
    };

    const formLabel: FormLabel = {
        edit: translate('form.edit'),
        entity: translate('environment'),
        new: translate('form.new'),
        reset: translate('form.reset'),
        save: translate('form.save')
    };

    const tableLabel: Partial<TableLabel> = {
        delete: translate('table.delete'),
        deleteRows: translate('table.deleteRows'),
        add: translate('table.add'),
        edit: translate('table.edit'),
        close: translate('table.close'),
        refresh: translate('table.refresh'),
        noRowsLabel: translate('table.noRowsLabel')
    }

    return (
        <ListDetail
            api={api}
            listEntity={{name: 'environmentCategory', label: 'Environment cateogry'}}
            listForm={environmentCategoryForm}
            detailEntity={{name: 'environment', label: 'Environment', select: ['environmentId', 'environmentName', 'environmentImg']}}
            detailForm={environmentForm}
            datagridProps={datagridProps}
            confirmDialogLabel={confirmDialogLabel}
            formLabel={formLabel}
            tableLabel={tableLabel}
            joinField="environmentCategoryId"
        />
    )
}