
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import React, { useEffect } from 'react';

export type ConfirmDialogLabel = {
    title?: string;
    text?: string;
    confirm?: string;
    cancel?: string;
}

type Props = {
    label?: ConfirmDialogLabel;
    open: boolean;
    onConfirm: () => void;
    onCancel?: () => void;
}
export default function ConfirmDialog(props: Props) {

    const { open, label, onConfirm, onCancel} = props;

    const [show, setShow] = React.useState(false);

    useEffect(() => setShow(open), [open, setShow]);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
    const handleClose = () => {
      setShow(false);
      if(onCancel) {
          onCancel();
      }
    };
  
    const handleConfirm = () => {
      setShow(false);
      onConfirm();
    };


    return (
          <Dialog
            fullScreen={fullScreen}
            open={show}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {label?.title}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>{label?.text}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose}>
                {label?.cancel ?? 'Cancel'}
              </Button>
              <Button onClick={handleConfirm} autoFocus>
              {label?.confirm ?? 'Ok'}
              </Button>
            </DialogActions>
          </Dialog>
      );
}