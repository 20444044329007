import { Box, CircularProgress, Collapse, Fab as MuiFab, SxProps, Tooltip } from "@mui/material"
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { PropTypes } from "@mui/material";
import { isMobile } from "react-device-detect";

type Props = {
    label: string;
    icon: JSX.Element;
    placement: 'bottom-end' | 'bottom-start' | 'top-end' | 'top-start';
    color?: PropTypes.Color | 'success' | 'error' | 'info' | 'warning';
    loading?: boolean;
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
    
}

export default function Fab(props: Props){

    const {label, icon, placement, color, loading, onClick} = props;

    const showMessage = useSelector((state: RootState) => state.snackbar.message !== null)

    let style: SxProps;
    switch (placement) {
        case 'top-start': style = { top: 32, left: 32}; break;
        case 'top-end': style = { top: 32, left: 32}; break;
        case 'bottom-start': style = { bottom: 32, right: 32}; break;
        case 'bottom-end': style = { bottom: 32, right: 32}; break;
    }

    return(
        <Box sx={{position: 'fixed', ...style}}>
            <Tooltip enterDelay={1000} enterNextDelay={1000} title={label}>
                <MuiFab
                    color={loading ? 'default' : color ?? 'primary'}
                    aria-label="add"
                    onClick={!loading ? (e) => onClick(e) : undefined}
                >
                    {loading ? <CircularProgress size={24} /> :icon}
                </MuiFab>
            </Tooltip>
            <Collapse in={showMessage && isMobile}><Box paddingBottom={4} /></Collapse>
        </Box>
    )
}
