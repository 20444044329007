import { toPascalCase } from '../../util';
import { BaseEntity } from '../entities/BaseEntity';
import { api } from './config';


export type Page<T extends BaseEntity> = { rows: T[], total: number};

const generateCrudApi = <T extends BaseEntity>(tag: string, route: string) => {
  
  const ensureId = (data: T[]): T[] => {
    return data.map(row => {
      const fieldId = Object.keys(row).at(0) ?? '';
      const fieldName = Object.keys(row).at(1) ?? '';
      return {
        id: row[fieldId],
        name: row[fieldName],
        ...row
      }
    })
  }

  const pascalCase = toPascalCase(tag);

  const enhancedApi = api.enhanceEndpoints({ addTagTypes: [tag] });

  const crudApi = enhancedApi.injectEndpoints({
    endpoints: (builder) => ({
      //findById
      [`find${pascalCase}ById`]: builder.query<T, string>({
        query: (id) => `${route}/${id}`,
        providesTags: (_post, _err, id) => [{ type: tag, id }],
        transformResponse: (response: any, meta, arg) => {
          return response.response;
        }
      }),
      //find
      [`find${pascalCase}`]: builder.query<Page<T>, string>({
        query: (params) => ({ url: `${route}/list/${params}` }),
        transformResponse: (response: any, meta, arg) => {
          return { rows: ensureId(response.response), total: response.response.length};
        },
        providesTags: (result = {rows: [], total: 0}) => [
          ...result.rows.map(({ id }) => ({ type: tag, id } as const)),
          { type: tag, id: 'LIST' },
        ],
      }),
      //findAll
      [`findAll${pascalCase}`]: builder.query<Page<T>, void>({
        query: () => ({ url: `${route}/list` }),
        transformResponse: (response: any, meta, arg) => {
          return { rows: ensureId(response.response), total: response.response.length};
        },
        providesTags: (result = {rows: [], total: 0}) => [
          ...result.rows.map(({ id }) => ({ type: tag, id } as const)),
          { type: tag, id: 'LIST' },
        ],
      }),
      //add
      [`add${pascalCase}`]: builder.mutation<T, Partial<T>>({
        query: (body) => ({
          url: route,
          method: 'POST',
          body,
        }),
        invalidatesTags: (result, error) => error ? [] : [{ type: tag, id: 'LIST' }],
      }),
      //update
      [`update${pascalCase}`]: builder.mutation<T, Partial<T>>({
        query(data) {
          const { id, ...body } = data
          return {
            url: `${route}/${id}`,
            method: 'PUT',
            body,
          }
        },
        invalidatesTags: (entity, error) => error ? [] : [{ type: tag, id: entity?.id }],
      }),
      //delete
      [`delete${pascalCase}`]: builder.mutation<{ success: boolean; idList: string[] }, string[]>({
        query(idList) {
          return {
            url: `${route}`,
            body: {idList: idList},
            method: 'DELETE',
          }
        },
        invalidatesTags: (entity, error) => error ? [] : [{ type: tag, id: 'LIST' }],
      }),
      //delete
      [`delete${pascalCase}ById`]: builder.mutation<{ success: boolean; id: string }, string[]>({
        query(id) {
          return {
            url: `${route}/${id}`,
            method: 'DELETE',
          }
        },
        invalidatesTags: (entity, error) => error ? [] : [{ type: tag, id: 'LIST' }],
      }),
    })
  });
  
  return crudApi;
}

export {
  generateCrudApi,
  api
};